//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TaskHistory",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    load() {},
  },
};
